import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { axios } from '../../utils/axios';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  frequencies: null | [];
  categories: null | [];
  subCategories: null | [];
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  frequencies: [],
  categories: [],
  subCategories: []
};

const slice = createSlice({
  name: 'frequency',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getFrequencySuccess(state, action) {
      state.isLoading = false;
      state.frequencies = action.payload;
    },
    getCategoriesSuccess(state, action) {
      console.log(action.payload, 'cattttt');
      state.subCategories = action.payload.subCategories;
      state.categories = action.payload.categories;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getFrequencies() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('rest/api/configuration/frequency');
      dispatch(slice.actions.getFrequencySuccess(response.data.frequencies));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCategories() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('rest/api/configuration/category');
      dispatch(slice.actions.getCategoriesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
