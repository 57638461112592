// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const CONFIGURATION = '/configuration';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // configuration: path(ROOTS_DASHBOARD, '/configuration'),
  inspections: path(ROOTS_DASHBOARD, '/inspections'),
  // checklistInspections: path(ROOTS_DASHBOARD, '/checklist'),
  checklistInspections: {
    root: path(ROOTS_DASHBOARD, '/checklist'),
    list: path(ROOTS_DASHBOARD, '/checklist/list'),
    details: path(ROOTS_DASHBOARD, '/checklist/list/details')
  },
  checksheet: {
    root: path(ROOTS_DASHBOARD, '/checksheet'),
    list: path(ROOTS_DASHBOARD, '/checksheet/list'),
    checklist: path(ROOTS_DASHBOARD, '/checksheet/checklist'),
    newUser: path(ROOTS_DASHBOARD, '/checksheet/new')
  },
  configuration: {
    root: path(ROOTS_DASHBOARD, '/configuration'),
    categories: path(ROOTS_DASHBOARD, '/configuration/categories'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/configuration/list'),
    newFrequency: path(ROOTS_DASHBOARD, '/configuration/new'),
    newCategories: path(ROOTS_DASHBOARD, '/configuration/categories/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};
